<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form
          :inline="true"
          ref="formData"
          :model="formData"
          class="demo-form-inline"
      >
        <el-form-item>
          <el-input
              placeholder="请输入内容"
              v-model="formData.keywords"
              class="input-with-select"
          >
            <el-select
                style="width: 120px"
                v-model="formData.searchField"
                slot="prepend"
                placeholder="请选择"
            >
              <el-option label="会员编号" value="1">会员编号</el-option>
              <el-option label="银行卡号" value="2">银行卡号</el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
          >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
        ref="dataTable"
        v-loading="loading"
        :data="tableData"
        border
        stripe
        style="width: 100%; margin-top: 20px"
        @selection-change="handleSelectionChange"
    >
      <el-table-column
          prop="id"
          label="#ID" width="50"
      >
      </el-table-column>
      <el-table-column
          prop="country"
          label="国家/地区"
          align="center"
          header-align="center"
          width="200"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.country.name }} - {{ scope.row.country.name_en }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          prop="member_info"
          label="经销商信息"
          width="300"
          header-align="center"
      >
        <template slot-scope="scope">
          <span
              v-if="scope.row.member_level === '1'"
              v-html="
              '姓名：' +
              scope.row.full_name +
              '<br />' +
              '手机号码：' +
              scope.row.mobile
            "
          ></span>
          <span
              v-if="scope.row.member_level === '3'"
              v-html="
              scope.row.full_name +
              '<br />' +
              '会员编号：' +
              scope.row.uname +
              '<br />' +
              '手机号码：' +
              scope.row.mobile +
              '<br />' +
              '身份证号：' +
              scope.row.identity_number
            "
          ></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="bank_name"
          label="所属银行" width="200">
      </el-table-column>
      <el-table-column
          prop="account_name"
          label="开户名" width="200"
      >
      </el-table-column>
      <el-table-column
          prop="card_number"
          label="银行卡号" width="200"
      >
      </el-table-column>
      <el-table-column
          prop="created_at"
          label="添加时间" width="200"
      >
      </el-table-column>
      <el-table-column
          prop="updated_at"
          label="最后更新" width="200"
      >
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          header-align="center"
      >
        <template slot-scope="scope">
          <el-button
              style="margin: 2px 0"
              @click="editBankCard(scope.row)"
              type="primary"
              plain
              size="small"
              v-if="myfun.checkAuthRule('adminFinanceEditBankCard')"
          >编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
          style="margin-top: 1rem"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total"
      >
      </el-pagination>
    </div>



    <!-- Form -->

    <el-dialog
        title="银行卡编辑"
        :visible.sync="editBankCardDialogFormVisible"
        v-loading="loading"
    >
      <el-form
          :model="editBankCarFormData"
          class="huiyuanxinxi"
          :rules="checkEditBankCarFormRules"
          ref="editBankCarFormData"
      >
        <el-form-item
            label="会员编号"
            :label-width="formLabelWidth"
            prop=""
        >
          <div style="text-align: left">{{ editBankCarFormData.uname }}</div>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth" prop="">
          <div style="text-align: left">{{ editBankCarFormData.full_name }}</div>
        </el-form-item>
        <el-form-item
            label="所属银行"
            :label-width="formLabelWidth"
            prop="bank_name"
            required
        >
          <el-input
              v-model="editBankCarFormData.bank_name"
              autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="开户名"
            :label-width="formLabelWidth"
            prop="account_name"
            required
        >
          <el-input
              v-model="editBankCarFormData.account_name"
              autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行卡号" :label-width="formLabelWidth" prop="card_number">
          <el-input
              v-model="editBankCarFormData.card_number"
              autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBankCardDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('editBankCarFormData')"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "MemberList",
    components: {},
    data() {
      return {
        formData: {
          page: 1,
          pagesize: 10,
          total: 0,
          keywords: "",
          searchField: "1",
          isExport:0
        },
        tableData: [],
        loading: false,
        formLabelWidth: "120px",
        editBankCardDialogFormVisible: false,
        editBankCarFormData: {
          id: "0",
          card_number: "",
          bank_name: "",
          account_name: "",
        },
        checkEditBankCarFormRules: {
          card_number: [{ required: true, message: "请输入银行卡号" }],
          bank_name: [{ required: true, message: "请输入银行名称" }],
          account_name: [{ required: true, message: "请输入开户名" }]
        },
      };
    },
    methods: {
      getList(page = 0) {
        this.tableData = [];
        if (page > 0) {
          this.formData.page = page;
        }
        this.loading = true;
        this.myfun.request("adminFinanceGetBankCardList.api", this.formData)
          .then((result) => {
            if (result.code === "1") {
              this.tableData = result.data.list;
            }
            this.formData.total = parseInt(result.data.total);
            this.loading = false;
          });
      },
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.formData.pagesize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.formData.page = val;
        this.getList();
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      handleClose(tag) {
        if (this.multipleSelection.length > 1) {
          this.$refs.dataTable.toggleRowSelection(tag, false);
        } else {
          this.$message.warning("请至少要保留一个操作项");
        }
      },
      editBankCard(row){
        this.editBankCarFormData = row;
        this.editBankCardDialogFormVisible = true;
      },
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            this.myfun.request('adminFinanceEditBankCard.api', this.editBankCarFormData)
              .then((result) => {
                if (result.code === "1") {
                  this.getList();
                  this.editBankCardDialogFormVisible = false;
                }
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      onSearch() {
        this.getList(1);
      },
    },
    created() {
      this.getList(1);
    },
    mounted() {},
    computed: {
      pageSizs() {
        return this.$store.state.pageSizes;
      },
    },
  };
</script>

<style>
  .huiyuanxinxi .el-form-item {
    margin: 0 0 15px 0;
  }
  .switshsdf {
    display: flex;
    flex-direction: column;
  }
  .mainwhite {
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }
  .mainwhite-tiop {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #f5f5f5;
    justify-content: flex-end;
  }

  .el-form-item {
    margin-bottom: 0;
  }
</style>
