<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form
          :inline="true"
          ref="formData"
          :model="formData"
          class="demo-form-inline"
      ><el-form-item>
        <el-select
            style="width: 250px"
            v-model="formData.country_id"
            placeholder="请选择国家/地区"
            @change="changeCountry" clearable
        >
          <el-option
              v-for="item in countryList"
              :key="item.id"
              :label="item.name + '-' + item.name_en"
              :value="item.id"
          >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.name_en
              }}</span>
          </el-option>
        </el-select>
      </el-form-item>
        <el-form-item v-if="false">
          <el-input
              placeholder="请输入内容"
              v-model="formData.keywords"
              class="input-with-select"
          >
            <el-select
                style="width: 120px"
                v-model="formData.searchField"
                slot="prepend"
                placeholder="请选择"
            >
              <el-option label="会员编号" value="1">会员编号</el-option>
              <el-option label="银行卡号" value="2">银行卡号</el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
          >查询</el-button
          >
        </el-form-item>
        <el-form-item v-if="myfun.checkAuthRule('adminFinanceAddBank')">
          <el-button type="primary" @click="addBank('formData')"
          >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
        ref="dataTable"
        v-loading="loading"
        :data="tableData"
        border
        stripe
        style="width: 100%; margin-top: 20px"
        @selection-change="handleSelectionChange"
    >
      <el-table-column
          prop="id"
          label="#ID" width="50"
      >
      </el-table-column>
      <el-table-column
          prop="country"
          label="国家/地区"
          align="center"
          header-align="center"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.country.name }} - {{ scope.row.country.name_en }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          prop="name"
          label="银行名称" >
      </el-table-column>
      <el-table-column
          prop="created_at"
          label="添加时间"
      >
      </el-table-column>
      <el-table-column
          prop="updated_at"
          label="最后更新"
      >
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          header-align="center"
      >
        <template slot-scope="scope">
		<div class="anniu">
          <el-button
              style="margin: 2px 0"
              @click="editBank(scope.row)"
              type="primary"
              plain
              size="small"
			  class="anniu_but"
              v-if="myfun.checkAuthRule('adminFinanceEditBank')"
          >编辑</el-button>

          <el-button
              style="margin: 2px 0"
              @click="delBank(scope.row)"
              type="primary"
              plain
              size="small"
			  class="anniu_but"
              v-if="myfun.checkAuthRule('adminFinanceDelBank')"
          >删除</el-button>
		  </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
          style="margin-top: 1rem"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total"
      >
      </el-pagination>
    </div>



    <!-- Form -->

    <el-dialog
        :title="addBankFormData.operate_tit"
        :visible.sync="addBankDialogFormVisible"
        v-loading="loading"
    >
      <el-form
          :model="addBankFormData"
          class="huiyuanxinxi"
          :rules="checkAddBankFormRules"
          ref="addBankFormData"
      >
        <el-form-item
            label="所属国家"
            :label-width="formLabelWidth"
            ref="searchType"
            prop="searchType"
        >
          <el-select
              style="width: 250px"
              v-model="addBankFormData.country_id"
              placeholder="请选择国家/地区" required
          >
            <el-option
                v-for="item in countryList"
                :key="item.id"
                :label="item.name + '-' + item.name_en"
                :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.name_en
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="银行名称"
            :label-width="formLabelWidth"
            prop="name"
            required
        >
          <el-input
              v-model="addBankFormData.name"
              autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addBankDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('addBankFormData')"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "BankList",
    components: {},
    data() {
      return {
        formData: {
          page: 1,
          pagesize: 10,
          total: 0,
          keywords: "",
          searchField: "1",
          isExport:0,
          country_id: ""
        },
        tableData: [],
        loading: false,
        countryList:[],
        formLabelWidth: "120px",
        addBankDialogFormVisible: false,
        addBankFormData: {
          id: "0",
          name: "",
          country_id: "",
          operate_type: 1,
          operate_tit: '新增银行'
        },
        checkAddBankFormRules: {
          country_id: [{ required: true, message: "请选择所属国家" }],
          name: [{ required: true, message: "请输入银行名称" }],
        },
      };
    },
    methods: {
      getList(page = 0) {
        this.tableData = [];
        if (page > 0) {
          this.formData.page = page;
        }
        this.loading = true;
        this.myfun.request("adminFinanceGetBankList.api", this.formData)
          .then((result) => {
            if (result.code === "1") {
              this.tableData = result.data.list;
            }
            this.formData.total = parseInt(result.data.total);
            this.loading = false;
          });
      },
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.formData.pagesize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.formData.page = val;
        this.getList();
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      handleClose(tag) {
        if (this.multipleSelection.length > 1) {
          this.$refs.dataTable.toggleRowSelection(tag, false);
        } else {
          this.$message.warning("请至少要保留一个操作项");
        }
      },
      editBankCard(row){
        this.editBankCarFormData = row;
        this.editBankCardDialogFormVisible = true;
      },
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            let apiname = this.addBankFormData.operate_type === 1 ? 'adminFinanceAddBank.api' : 'adminFinanceEditBank.api';
            this.myfun.request(apiname, this.addBankFormData)
              .then((result) => {
                if (result.code === "1") {
                  this.getList();
                  this.addBankDialogFormVisible = false;
                }
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      onSearch() {
        this.getList(1);
      },
      addBank(){
        this.addBankFormData.operate_tit = '新增银行';
        this.addBankFormData.operate_type = 1;
        this.addBankDialogFormVisible = true;
      },

      editBank(row){
        this.addBankFormData.operate_tit = '编辑银行';
        this.addBankFormData = row;
        this.addBankFormData.operate_type = 2;
        this.addBankDialogFormVisible = true;
      },

      delBank(row){
        this.$confirm("此操作将永久删除该银行, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.loading = true;
            this.myfun.request("adminFinanceDelBank.api", { id: row.id })
              .then((result) => {
                if (result.code === "1") {
                  this.getList();
                }
                this.loading = false;
              });
          })
          .catch(() => {
            this.$message.info('操作取消');
          });
      },

      changeCountry() {
        this.getList();
      },

    },
    created() {
      this.loading = true;
      this.myfun.request("CommonPubGetCountryList.api").then((result) => {
        if (result.code === "1") {
          this.countryList = result.data;
          // this.formData.country_id = this.countryList[0].id;
          this.countryList = result.data;
          // this.registerMemberformData.country_id = this.countryList[0].id;
          this.getList(1);
        }
      })

    },
    mounted() {},
    computed: {
      pageSizs() {
        return this.$store.state.pageSizes;
      },
    },
  };
</script>

<style>
  .huiyuanxinxi .el-form-item {
    margin: 0 0 15px 0;
  }
  .switshsdf {
    display: flex;
    flex-direction: column;
  }
  .mainwhite {
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }
  .mainwhite-tiop {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #f5f5f5;
    justify-content: flex-end;
  }

  .el-form-item {
    margin-bottom: 0;
  }
  /*  */
  .anniu .anniu_but{
	  margin: 0px 5px !important;
  }
</style>
