<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form
        :inline="true"
        ref="formData"
        :model="formData"
        class="demo-form-inline"
        style="text-align: left"
      >
        <el-form-item>
          <el-input
            placeholder="请输入内容"
            v-model="formData.keywords"
            class="input-with-select"
          >
            <el-select
              class="el-select"
              v-model="formData.searchField"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="申请编号" value="1">申请编号</el-option>
              <el-option label="会员编号" value="2">会员编号</el-option>
              <!--<el-option label="手机号" value="3">手机号</el-option>-->
              <!--              <el-option label="身份证号" value="3">身份证号</el-option>-->
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            style="width: 200px"
            v-model="formData.memberAccountId"
            placeholder="请选择钱包类型"
            @change="changeMemberAccount"
          >
            <el-option
              v-for="item in accountLogTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            class="el-select"
            style="width: 350px"
            v-model="formData.memberAccountLogTypeId"
            clearable
            multiple
            placeholder="请选择申请类型"
            @change="changeSearch"
          >
            <el-option
              v-for="item in logTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      v-loading="loading"
      stripe
      border
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column
        prop="id"
        label="#ID"
        width="50"
        align="center"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        prop="apsn"
        label="申请编号"
        align="center"
        header-align="center"
        width="250"
      >
        <template slot-scope="scope">
          <span v-html="scope.row.apsn"></span>
        </template>
      </el-table-column>
      <el-table-column prop="member_info" label="变更账户会员信息" width="300">
        <template slot-scope="scope">
          <span
            v-if="scope.row.member_level === '1'"
            v-html="
              '姓名：' +
              scope.row.full_name +
              ' / ' +
              '手机号码：' +
              scope.row.mobile
            "
          ></span>
          <span
            v-if="scope.row.member_level === '3'"
            v-html="
              scope.row.full_name +
              '<br />' +
              '会员编号：' +
              scope.row.uname +
              '<br />' +
              '手机号码：' +
              scope.row.mobile +
              '<br />' +
              '身份证号：' +
              scope.row.identity_number
            "
          ></span>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        label="申请人"
        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <span v-html="scope.row.apply_user.full_name"></span>
        </template>
      </el-table-column>
      <el-table-column
        prop="log_type_name"
        label="申请类型"
        align="center"
        header-align="center"
      >
      </el-table-column>
      <el-table-column align="center" prop="amount" label="钱包类型">
        <template slot-scope="scope">
          <span v-html="scope.row.member_account.name"></span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="amount"
        :label="'申请金额'"
      >
        <template slot-scope="scope">
          <span v-html="scope.row.amount"></span>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="申请时间"
        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <span v-html="scope.row.created_at"></span>
        </template>
      </el-table-column>

      <el-table-column
        prop="status_str"
        label="状态"
        align="center"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        prop=""
        label="操作"
        align="center"
        header-align="center"
        width="150"
      >
        <template slot-scope="scope">
          <el-button
            @click="apply(scope.row)"
            type="danger"
            plain
            size="small"
            v-if="
              myfun.checkAuthRule('adminFinanceReviewApply') &&
              scope.row.status === '0'
            "
            >审核</el-button
          >
          <el-button
            @click="view(scope.row)"
            type="info"
            plain
            size="small"
            v-else
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-table
        v-loading="loading"
        :data="tableData" border stripe style="width: 100%; margin-top: 20px;">
      <el-table-column
          prop="id"
          label="#ID" width="50"
          >
      </el-table-column>
      <el-table-column
          prop="apsn"
          label="申请信息" width="250"
      >
        <template slot-scope="scope">
          <span v-html="
          '编号：'+scope.row.apsn+'<br />'+
          '申请人：'+scope.row.apply_user.full_name+'<br />'+
          '审核人：'+scope.row.operate_user.full_name
"></span>
        </template>
      </el-table-column>

      <el-table-column
          prop="member_info"
          label="会员信息" width="210"
          >
        <template slot-scope="scope">
          <span v-if="scope.row.member_level === '1'" v-html="
          '真实姓名：'+scope.row.full_name+'<br />'+
          '手机号码：'+scope.row.mobile
"></span>
          <span v-if="scope.row.member_level === '3'" v-html="
          '会员编号：'+scope.row.uname+'<br />'+
          '真实姓名：'+scope.row.full_name+'<br />'+
          '手机号码：'+scope.row.mobile
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="log_type_name"
          label="申请类型"
      >
      </el-table-column>
      <el-table-column
          prop="amount"
          label="操作金额"
      >
        <template slot-scope="scope">
          <span v-html="
          '申请金额：'+scope.row.amount+'<br />'+
          '手续费：'+scope.row.fee+'<br />'+
          '操作金额：'+scope.row.real_amount
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="操作时间"
      >
        <template slot-scope="scope">
          <span v-html="
          '申请时间：'+scope.row.account_name+'<br />'+
          '审核时间：'+scope.row.card_number
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="附属信息"
      >
        <template slot-scope="scope">
          <span v-html="
          '账户名：'+scope.row.account_name+'<br />'+
          '所属银行：'+scope.row.bank_name+'<br />'+
          '银行卡号：'+scope.row.card_number
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="状态"
      >
      </el-table-column>
      <el-table-column
          prop="apply_remark"
          label="备注"
      >
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button @click="apply(scope.row)" type="text" size="small" v-if="myfun.checkAuthRule('adminFinanceReviewApply') && scope.row.status === '0'">审核</el-button>
          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table> -->
    <div class="block">
      <el-pagination
        style="margin-top: 1rem"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        background
        :page-sizes="pageSizs"
        :page-size="formData.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="formData.total"
      >
      </el-pagination>
    </div>

    <!-- Form -->

    <el-dialog
      title="申请详情"
      :visible.sync="applyDialogFormVisible"
      v-loading="loading"
    >
      <el-form
        class="ally"
        :model="applyFormData"
        :rules="checkApplyformRules"
        ref="applyFormData"
      >
        <!--<el-form-item-->
        <!--label="是否通过"-->
        <!--:label-width="formLabelWidth" style="text-align: left"-->
        <!--prop="settle_status"-->
        <!--&gt;-->
        <!--<el-switch :disabled="!myfun.checkAuthRule('adminMemberEditMember')"-->
        <!--class="switch custom"-->
        <!--v-model="applyFormData.status"-->
        <!--:active-value="'1'"-->
        <!--:inactive-value="'0'"-->
        <!--active-text="通过"-->
        <!--inactive-text="不通过"-->
        <!--&gt;</el-switch>-->
        <!--</el-form-item>-->

        <el-form-item label="申请编号" :label-width="formLabelWidth" prop="">
          <!--    <div>{{ applyFormData.apsn }}</div> -->
          <el-input v-model="applyFormData.apsn" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" :label-width="formLabelWidth" prop="">
          <!-- <div>{{ applyFormData.full_name }}</div> -->
          <el-input
            v-model="applyFormData.full_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="会员编号" :label-width="formLabelWidth" prop="">
          <!-- <div>{{ applyFormData.uname }}</div> -->
          <el-input v-model="applyFormData.uname" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="申请人" :label-width="formLabelWidth" prop="">
          <!-- <div>{{ applyFormData.apply_user_full_name }}</div> -->
          <el-input
            v-model="applyFormData.apply_user_full_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请类型" :label-width="formLabelWidth" prop="">
          <!-- <div>{{ applyFormData.log_type_name }}</div> -->
          <el-input
            v-model="applyFormData.log_type_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="钱包类型" :label-width="formLabelWidth" prop="">
          <!-- <div>{{ applyFormData.member_account_name }}</div> -->
          <el-input
            v-model="applyFormData.member_account_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="'申请金额'"
          :label-width="formLabelWidth"
          prop=""
        >
          <!-- <div>{{ applyFormData.amount }}</div> -->
          <el-input v-model="applyFormData.amount" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="手续费" :label-width="formLabelWidth" prop="">
          <!--  <div>{{ applyFormData.fee }}</div> -->
          <el-input v-model="applyFormData.fee" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item
          label="实际到账金额"
          :label-width="formLabelWidth"
          prop=""
        >
          <!--    <div>{{ applyFormData.real_amount }}</div> -->
          <el-input
            v-model="applyFormData.real_amount"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请时间" :label-width="formLabelWidth" prop="">
          <!-- <div>{{ applyFormData.created_at }}</div> -->
          <el-input
            v-model="applyFormData.created_at"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth" prop="">
          <!--         <div>{{ applyFormData.status_str }}</div> -->
          <el-input
            v-model="applyFormData.status_str"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="账户名"
          :label-width="formLabelWidth"
          prop=""
          v-if="
            applyFormData.member_account_log_type_id == 15 ||
            applyFormData.member_account_log_type_id == 16 ||
            applyFormData.member_account_log_type_id == 17
          "
        >
          <!-- <div>{{ applyFormData.account_name }}</div> -->
          <el-input
            v-model="applyFormData.account_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="所属银行"
          :label-width="formLabelWidth"
          prop=""
          v-if="
            applyFormData.member_account_log_type_id == 15 ||
            applyFormData.member_account_log_type_id == 16 ||
            applyFormData.member_account_log_type_id == 17
          "
        >
          <!-- <div>{{ applyFormData.bank_name }}</div> -->
          <el-input
            v-model="applyFormData.bank_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="银行卡号"
          :label-width="formLabelWidth"
          prop=""
          v-if="
            applyFormData.member_account_log_type_id == 15 ||
            applyFormData.member_account_log_type_id == 16 ||
            applyFormData.member_account_log_type_id == 17
          "
        >
          <!--   <div>{{ applyFormData.card_number }}</div> -->
          <el-input
            v-model="applyFormData.card_number"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核人" :label-width="formLabelWidth" prop="">
          <!-- <div>{{ applyFormData.operate_user_full_name }}</div> -->
          <el-input
            v-model="applyFormData.operate_user_full_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核时间" :label-width="formLabelWidth" prop="">
          <!-- <div>{{ applyFormData.apply_time }}</div> -->
          <el-input
            v-model="applyFormData.apply_time"
            :disabled="true"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="备注"
          :label-width="formLabelWidth"
          prop="operate_remark"
        >
          <el-input
            v-model="applyFormData.operate_remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="applyDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doApply('applyFormData', 0)"
          >审核拒绝</el-button
        >
        <el-button type="primary" @click="doApply('applyFormData', 1)"
          >审核通过</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="申请详情"
      class="ally"
      :visible.sync="viewDialogFormVisible"
      v-loading="loading"
    >
      <el-form :model="viewFormData" ref="viewFormData">
        <el-form-item label="申请编号" :label-width="formLabelWidth" prop="">
          <el-input v-model="viewFormData.apsn" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" :label-width="formLabelWidth" prop="">
          <el-input
            v-model="viewFormData.full_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="会员编号" :label-width="formLabelWidth" prop="">
          <el-input v-model="viewFormData.uname" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="申请人" :label-width="formLabelWidth" prop="">
          <el-input
            v-model="viewFormData.apply_user_full_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请类型" :label-width="formLabelWidth" prop="">
          <el-input
            v-model="viewFormData.log_type_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="钱包类型" :label-width="formLabelWidth" prop="">
          <el-input
            v-model="viewFormData.member_account_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="'申请金额'"
          :label-width="formLabelWidth"
          prop=""
        >
          <el-input v-model="viewFormData.amount" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="手续费" :label-width="formLabelWidth" prop="">
          <el-input v-model="viewFormData.fee" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item
          label="实际到账金额"
          :label-width="formLabelWidth"
          prop=""
        >
          <el-input
            v-model="viewFormData.real_amount"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请时间" :label-width="formLabelWidth" prop="">
          <el-input
            v-model="viewFormData.created_at"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth" prop="">
          <el-input
            v-model="viewFormData.status_str"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="账户名"
          :label-width="formLabelWidth"
          prop=""
          v-if="
            viewFormData.member_account_log_type_id == 15 ||
            viewFormData.member_account_log_type_id == 16 ||
            viewFormData.member_account_log_type_id == 17
          "
        >
          <el-input
            v-model="viewFormData.account_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="所属银行"
          :label-width="formLabelWidth"
          prop=""
          v-if="
            viewFormData.member_account_log_type_id == 15 ||
            viewFormData.member_account_log_type_id == 16 ||
            viewFormData.member_account_log_type_id == 17
          "
        >
          <el-input
            v-model="viewFormData.bank_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="银行卡号"
          :label-width="formLabelWidth"
          prop=""
          v-if="
            viewFormData.member_account_log_type_id == 15 ||
            viewFormData.member_account_log_type_id == 16 ||
            viewFormData.member_account_log_type_id == 17
          "
        >
          <el-input
            v-model="viewFormData.card_number"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核人" :label-width="formLabelWidth" prop="">
          <el-input
            v-model="viewFormData.operate_user_full_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核时间" :label-width="formLabelWidth" prop="">
          <el-input
            v-model="viewFormData.apply_time"
            :disabled="true"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="备注"
          :label-width="formLabelWidth"
          prop="operate_remark"
        >
          <el-input
            v-model="viewFormData.operate_remark"
            :disabled="true"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="viewDialogFormVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MemberList",
  components: {},
  data() {
    return {
      test: true,

      formData: {
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
        memberAccountId: "",
        memberAccountLogTypeId: [],
      },
      tableData: [],
      loading: false,
      applyDialogFormVisible: false,
      viewDialogFormVisible: false,
      applyFormData: {
        ids: "0",
        status: 1,
        operate_remark: "",
        //展示表单
        apsn: "",
        full_name: "", //真实姓名
        uname: "", //会员编号
        apply_user_full_name: "", //申请人apply_user.full_name
        log_type_name: "", //申请类型
        member_account_name: "", //钱包类型member_account.name
        amount: "", //申请金额
        fee: "", //手续费
        real_amount: "", //实际到账金额
        created_at: "", //申请时间
        status_str: "", //状态
        account_name: "", //账户名
        bank_name: "", //所属银行
        card_number: "", //银行卡号
        operate_user_full_name: "", //审核人operate_user.full_name
        apply_time: "", //审核时间
        member_account_log_type_id: "",
      },
      viewFormData: {
        ids: "0",
        status: 1,
        operate_remark: "",
        //展示表单
        apsn: "",
        full_name: "", //真实姓名
        uname: "", //会员编号
        apply_user_full_name: "", //申请人apply_user.full_name
        log_type_name: "", //申请类型
        member_account_name: "", //钱包类型member_account.name
        amount: "", //申请金额
        fee: "", //手续费
        real_amount: "", //实际到账金额
        created_at: "", //申请时间
        status_str: "", //状态
        account_name: "", //账户名
        bank_name: "", //所属银行
        card_number: "", //银行卡号
        operate_user_full_name: "", //审核人operate_user.full_name
        apply_time: "", //审核时间
        member_account_log_type_id: "",
      },
      checkApplyformRules: {},
      formLabelWidth: "120px",
      logTypeList: [],
      accountLogTypeList: [],
    };
  },
  methods: {
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun
        .request("adminFinanceGetApplyList.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            console.log(result.data);
            this.tableData = result.data.list;
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    onSearch() {
      this.getList(1);
    },

    apply(row) {
      this.applyFormData.ids = row.id;
      this.applyFormData.status = row.status;
      this.applyDialogFormVisible = true;

      this.applyFormData.apsn = row.apsn;
      this.applyFormData.full_name = row.full_name;
      this.applyFormData.uname = row.uname;
      this.applyFormData.apply_user_full_name = row.apply_user.full_name;
      this.applyFormData.log_type_name = row.log_type_name;
      this.applyFormData.member_account_name = row.member_account.name;
      this.applyFormData.amount = row.amount;
      this.applyFormData.fee = row.fee;
      this.applyFormData.real_amount = row.real_amount;
      this.applyFormData.created_at = row.created_at;
      this.applyFormData.status_str = row.status_str;
      this.applyFormData.account_name = row.account_name;
      this.applyFormData.bank_name = row.bank_name;
      this.applyFormData.card_number = row.card_number;
      this.applyFormData.operate_user_full_name = row.operate_user.full_name;
      this.applyFormData.apply_time = row.apply_time;
      this.applyFormData.member_account_log_type_id =
        row.member_account_log_type_id;
    },
    view(row) {
      this.viewFormData.ids = row.id;
      this.viewFormData.status = row.status;
      this.viewDialogFormVisible = true;

      this.viewFormData.apsn = row.apsn;
      this.viewFormData.full_name = row.full_name;
      this.viewFormData.uname = row.uname;
      this.viewFormData.apply_user_full_name = row.apply_user.full_name;
      this.viewFormData.log_type_name = row.log_type_name;
      this.viewFormData.member_account_name = row.member_account.name;
      this.viewFormData.amount = row.amount;
      this.viewFormData.fee = row.fee;
      this.viewFormData.real_amount = row.real_amount;
      this.viewFormData.created_at = row.created_at;
      this.viewFormData.status_str = row.status_str;
      this.viewFormData.account_name = row.account_name;
      this.viewFormData.bank_name = row.bank_name;
      this.viewFormData.card_number = row.card_number;
      this.viewFormData.operate_user_full_name = row.operate_user.full_name;
      this.viewFormData.apply_time = row.apply_time;
      this.viewFormData.member_account_log_type_id =
        row.member_account_log_type_id;
    },
    doApply(formName, status) {
      this.applyFormData.status = status;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          this.myfun
            .request("adminFinanceReviewApply.api", this.applyFormData)
            .then((result) => {
              if (result.code === "1") {
                this.getList();
                this.applyDialogFormVisible = false;
              }
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeMemberAccount(memberAccountId) {
      for (let i in this.accountLogTypeList) {
        if (this.accountLogTypeList[i].id === memberAccountId) {
          this.logTypeList = this.accountLogTypeList[i].member_account_log_type;
          this.formData.memberAccountLogTypeId = [];
          this.getList();
        }
      }
    },
    changeSearch() {
      this.getList();
    },
  },
  created() {
    //adminFinanceGetApplyListDeputy
    this.loading = true;
    this.myfun
      .request("adminFinanceGetApplyListDeputy.api")
      .then((result) => {
        if (result.code === "1") {
          this.accountLogTypeList = result.data.accountLogTypeList;
          this.formData.memberAccountId = this.accountLogTypeList[0].id;
          this.logTypeList = this.accountLogTypeList[0].member_account_log_type;
          this.getList(1);
        }
      });
  },
  mounted() {},
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.el-form-item {
  margin-bottom: 0;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.el-select {
  width: 110px;
}
.country {
  width: 300px !important;
}
/* 申请详情 */
.ally .el-form-item__label {
  text-align: left;
}
.ally .el-form-item {
  margin-bottom: 15px !important;
}
</style>
